import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import SponsorClientLogo from "../components/SponsorClientLogo";
import MediaPartners from "../components/MediaPartners";
import Gallery from "../components/Gallery";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Vote from "../components/Vote";
import VideoBackground from "../components/VideoBackground";

function Home() {
  return (
    <div>
      <Header />
      <Banner />
      <SponsorClientLogo />
      <MediaPartners />
      <Gallery />
      {/* <Categories /> */}
      <VideoBackground />
      <Vote />
      <Footer />
    </div>
  );
}

export default Home;

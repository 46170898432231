import React from "react";

function IntroStart() {
  return (
    <section id="about" class="ts-intro event-intro">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h2 class="section-title white">
              <span>Why Join Us</span>
              About The Gleams Awards: Empowering Talent, Celebrating Vision.
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="single-intro-text">
              {/* <i class="icon-speaker"></i>
              <h3 class="ts-title">Great Speakers</h3> */}
              <p>
                The Gleams Awards is a prestigious platform, honoring the
                brilliant minds lighting up the worlds of entertainment,
                entrepreneurship, and business. We throw a dazzling spotlight on
                those who add sparkle to these industries, not just to applaud
                their achievements but to ignite a fire of inspiration for
                future stars. Through our dazzling awards ceremony and vibrant
                events, we bring together a community dedicated to breaking
                boundaries and setting new standards. The Gleams Awards is a
                subsidiary of Zintra Media - An omnific and innovative media
                powerhouse at the dynamic intersection of marketing, creative
                academy hub, and film production.
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="single-intro-text">
              <i class="icon-people"></i>
              <h3 class="ts-title">Vision</h3>
              <p>
                At The Gleams Awards, we dream of creating a magical realm where
                the heroes of entertainment, entrepreneurship, and business in
                Nigeria and Africa are celebrated like never before. Picture us
                as the champions of greatness, setting the stage for creators,
                dreamers, and leaders to shine bright like diamonds in the sky.
                Our aim? To be the ultimate symbol of excellence, a guiding
                light for those who dare to push boundaries and chase their
                dreams.
              </p>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="single-intro-text">
              <i class="icon-people"></i>
              <h3 class="ts-title">Mission:</h3>
              <p>
                Our mission at The Gleams Awards is to be the ultimate
                cheerleaders for the incredible individuals and companies
                changing the game in Nigeria and across Africa. We strive to
                build a welcoming stage where success stories are shared,
                connections are formed, and history is made. By showcasing top
                talents, we aim to fuel a spirit of ambition, grit, and
                brilliance that will shape a brighter future for our communities
                and continent as a whole. Let's sparkle together and create a
                legacy that glows for generations to come!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroStart;

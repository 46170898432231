import React from "react";
import gallery10 from "../assets/images/gallery/gallery10.png";
import gallery11 from "../assets/images/gallery/gallery11.png";
import gallery6 from "../assets/images/gallery/gallery6.jpg";
import shap from "../assets/images/shap/home_schedule_memphis2.png";

function MainGallery() {
  return (
    <div  style={{marginTop: '30px'}}>
      <section className="ts-gallery p-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto" style={{marginTop: '50px', marginBottom: '10px'}}>
              <h2 className="section-title white">
                {/* <span>Sneckpick</span> */}
                Event Gallery
              </h2>
            </div>
          </div>
          <div className="row gallery-wrap">
            <div className="col-lg-8">
              <a href={gallery10} className="ts-popup">
                <img className="img-fluid gallery-1" src={gallery10} alt="" />
              </a>
            </div>
            <div className="col-lg-4 pl-0">
              <a href={gallery11} className="ts-popup">
                <img className="img-fluid gallery-2" src={gallery11} alt="" />
              </a>
              <a href={gallery6} className="ts-popup">
                <img className="img-fluid gallery-3" src={gallery6} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="speaker-shap">
          <img className="shap1" src={shap} alt="" />
        </div>
      </section>
    </div>
  );
}

export default MainGallery;

import React from 'react';
import Header from '../components/Header';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

function Contact() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <div style={{ flex: '1 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 0', paddingTop: '150px' }}>
        <ContactForm />
      </div>
      <Footer style={{ marginTop: '50px' }} />
    </div>
  );
}

export default Contact;

import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import blg1 from '../../assets/images/nominees/blog_of_the_year/blg1.jpg';
import blg2 from '../../assets/images/nominees/blog_of_the_year/blg2.jpg';
import blg3 from '../../assets/images/nominees/blog_of_the_year/blg3.jpg';
import blg4 from '../../assets/images/nominees/blog_of_the_year/blg4.jpg';
import blg5 from '../../assets/images/nominees/blog_of_the_year/blg5.jpg';
import blg6 from '../../assets/images/nominees/blog_of_the_year/blg6.jpg';

function Blog() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={blg1}
            alt="blg1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Gossip Mill', 'Blog of The Year')}
          />
          <img
            src={blg2}
            alt="blg2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('InstaBlog', 'Blog of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={blg3}
            alt="blg3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('King Tunde Ednut Blog', 'Blog of The Year')}
          />
          <img
            src={blg4}
            alt="blg4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Yabaleft Online', 'Blog of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={blg5}
            alt="blg5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Remedey Blog', 'Blog of The Year')}
          />
          <img
            src={blg6}
            alt="blg6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Linda Ikeji Blog', 'Blog of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default Blog;

import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

function MessageModal({ open, handleClose, success }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {success ? (
          <>
            <CheckCircleOutlineIcon style={{ fontSize: 50, color: "green" }} />
            <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
              Vote submitted successfully!
            </Typography>
          </>
        ) : (
          <>
            <ErrorOutlineIcon style={{ fontSize: 50, color: "red" }} />
            <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
              Failed to submit vote. Please try again.
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default MessageModal;

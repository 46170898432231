import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from 'axios';
import MessageModal from './MessageModal';
import LoaderModal from './LoaderModal';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Add this line to make the modal scrollable
};

function VoteForm({ open, setOpen, nomineeName, nomineeTitle }) {
  const handleClose = () => setOpen(false);

  // State hooks for each form field
 
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [yourPhone, setYourPhone] = useState("");

  // State hooks for message modal
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  // State to disable form fields on success
  const [formDisabled, setFormDisabled] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderOpen(true); // Show loader modal

    const formData = {
      yourName,
      yourEmail,
      yourPhone,
      nomineeName,
      nomineeTitle
    };

    try {
      const response = await axios.post('https://cryptobattle.site/vote.php', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Ensure loader is visible for at least 3 seconds
      setTimeout(() => {
        setLoaderOpen(false); // Hide loader modal

        if (response.data.success) {
          setSuccess(true);
          setFormDisabled(true); // Disable form fields
          setMessageModalOpen(true); // Show success modal
          setOpen(false);
        } else {
          setSuccess(false);
          setMessageModalOpen(true); // Show failure modal
        }
      }, 3000); // Delay of 3 seconds
    } catch (error) {
      console.error('Error submitting vote:', error);
      
      // Ensure loader is visible for at least 3 seconds
      setTimeout(() => {
        setLoaderOpen(false); // Hide loader modal
        setSuccess(false);
        setMessageModalOpen(true); // Show failure modal
      }, 3000); // Delay of 3 seconds
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              {nomineeName}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              {nomineeTitle}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Your Name"
                value={yourName}
                onChange={(e) => setYourName(e.target.value)}
                style={{ marginBottom: "20px" }}
                disabled={formDisabled}
              />
              <TextField
                fullWidth
                label="Your Email"
                value={yourEmail}
                onChange={(e) => setYourEmail(e.target.value)}
                style={{ marginBottom: "20px" }}
                disabled={formDisabled}
              />
              <TextField
                fullWidth
                label="Your Phone Number"
                value={yourPhone}
                onChange={(e) => setYourPhone(e.target.value)}
                style={{ marginBottom: "20px" }}
                disabled={formDisabled}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                style={{ marginTop: "10px" }}
                disabled={formDisabled}
              >
                Submit Vote
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
      <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} success={success} />
      <LoaderModal open={loaderOpen} />
    </div>
  );
}

export default VoteForm;

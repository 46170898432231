import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Nominate from "./Nominate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

function Categories() {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const categories = [
    "BEST MUSIC VIDEO FEMALE DIRECTOR OF THE YEAR",
    "BEST MUSIC VIDEO MALE DIRECTOR OF THE YEAR",
    "BEST  RADIO STATION OF THE YEAR",
    "BEST TV STATION OF THE YEAR",
    "BEST CABLE STATION OF THE YEAR",
    "BEST BLOG OF THE YEAR",
    "BEST ONLINE MUSIC PLATFORM OF THE YEAR",
    "BEST ACTOR OF THE YEAR",
    "BEST ACTRESS OF THE YEAR",
    "EMERGING ACTOR OF THE YEAR",
    "EMERGING ACTRESS OF THE YEAR",
    "BEST TV PERSONALITY OF THE YEAR",
    "BEST SUPPORTIVE BRAND OF THE YEAR",
    "BEST CONTENT CREATOR OF THE YEAR",
    "BIGGEST SONG OF THE YEAR",
    "BEST MALE ARTIST OF THE YEAR",
    "BEST FEMALE ARTIST OF THE YEAR",
    "BIGGEST ARTIST OF THE YEAR (AFRICA)",
    "EMERGING ARTIST OF THE YEAR",
    "MUSIC PRODUCER OF THE YEAR",
    "BEST STREET SONG OF THE YEAR",
    "BEST MOVIE OF THE YEAR",
    "BEST MOVIE DIRECTOR OF THE YEAR",
    "MAN OF THE YEAR",
    "HUMANITARIAN OF THE YEAR",
    "YOUNG ACHIEVER OF THE YEAR (MALE)",
    "YOUNG ACHIEVER OF THE YEAR (FEMALE)",
    "BEST FASHION DESIGNER OF THE YEAR",
    "WOMAN OF THE YEAR",
    "ENTREPRENEUR OF THE YEAR",
    "BEST PODCAST OF THE YEAR",
    "LIFETIME ACHIEVEMENT AWARD",
  ];

  const handleButtonClick = (title) => {
    setSelectedCategory(title);
    setOpen(true);
  };

  return (
    <div
      className="row"
      id="categories"
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h4 style={{ color: "#ffffff", marginBottom: "20px" }}>CATEGORIES</h4>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "30px",
          }}
        >
          {categories.map((title, index) => (
            <Grid item xs={6} sm={6} md={4} lg={2.5} key={index}>
              <Button
                component={Item}
                fullWidth
                children={capitalizeEachWord(title)}
                onClick={() => handleButtonClick(title)}
                sx={{
                  border: "2px solid #fff",
                  borderRadius: "4px",
                  textTransform: "none", // Prevent automatic capitalization
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Nominate open={open} setOpen={setOpen} category={selectedCategory} />
    </div>
  );
}

export default Categories;

import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import eas1 from '../../assets/images/nominees/emerging_actress/eas1.jpg';
import eas2 from '../../assets/images/nominees/emerging_actress/eas2.jpg';
import eas3 from '../../assets/images/nominees/emerging_actress/eas3.jpg';
import eas4 from '../../assets/images/nominees/emerging_actress/eas4.jpg';
// import eas5 from '../../assets/images/nominees/emerging_actress/eas5.jpg';
import eas6 from '../../assets/images/nominees/emerging_actress/eas6.jpg';

function EmergingActress() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={eas1}
            alt="eas1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Fadesaye Olagbebi', 'Emerging Actress')}
          />
          <img
            src={eas2}
            alt="eas2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Betty Klair', 'Emerging Actress')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={eas3}
            alt="eas3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Cynthia Ebijie', 'Emerging Actress')}
          />
          <img
            src={eas4}
            alt="eas4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Omeche Oko', 'Emerging Actress')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          {/* <img
            src={eas5}
            alt="eas5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Mersea', 'Emerging Actress')}
          /> */}
          <img
            src={eas6}
            alt="eas6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Ekama Etim Inyang', 'Emerging Actress')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default EmergingActress;

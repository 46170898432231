import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import bga1 from '../../assets/images/nominees/biggest_artist_of_the_year/bga1.jpg';
import bga2 from '../../assets/images/nominees/biggest_artist_of_the_year/bga2.jpg';
import bga3 from '../../assets/images/nominees/biggest_artist_of_the_year/bga3.jpg';
import bga4 from '../../assets/images/nominees/biggest_artist_of_the_year/bga4.jpg';
// import bga5 from '../../assets/images/nominees/biggest_artist_of_the_year/bga5.jpg';
import bga6 from '../../assets/images/nominees/biggest_artist_of_the_year/bga6.jpg';
import bga7 from '../../assets/images/nominees/biggest_artist_of_the_year/bga7.jpg';

function BiggestArtistAfrica() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={bga1}
            alt="bga1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Wizkid', 'Biggest Artist of The Year (Africa)')}
          />
          <img
            src={bga2}
            alt="bga2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Tyla', 'Biggest Artist of The Year (Africa)')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bga3}
            alt="bga3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Davido', 'Biggest Artist of The Year (Africa)')}
          />
          <img
            src={bga4}
            alt="bga4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Rema', 'Biggest Artist of The Year (Africa)')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bga7}
            alt="bga7"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Diamond Platinumz', 'Biggest Artist of The Year (Africa)')}
          />
          <img
            src={bga6}
            alt="bga6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Burna Boy', 'Biggest Artist of The Year (Africa)')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default BiggestArtistAfrica;

import React from "react";
import sponsor1 from "../assets/images/sponsors/sponsor1.png";
import sponsor2 from "../assets/images/sponsors/sponsor2.png";
import sponsor3 from "../assets/images/sponsors/sponsor3.png";
import sponsor4 from "../assets/images/sponsors/sponsor4.png";
import sponsor5 from "../assets/images/sponsors/sponsor5.png";
import "../assets/css/grid.css";

function SponsorClientLogo() {
  return (
    <section id="sponsors" class="ts-sponsors sponsors-border">
      <div class="container">
        <div
          class="row"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "20px",
            marginTop: "30px",
            // marginBottom: "10px",
          }}
        >
          {/* <h3 style={{ color: "white" }}>In Partnership With</h3> */}
        </div>
        <div
          class="row sponsors-wrap"
          style={{
            display: "flex",
            // width: "300px",
            alignItems: "center",
            justifyContent: "center",
            // height: "50px",
            // // padding: "20px",
            // marginTop: "20px",
            // marginBottom: "10px",
            // backgroundColor: 'yellow'
          }}
        >
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 supported-by">
            <h3 style={{ color: "white" }}>Supported By</h3>
          </div>
          <div
            class="col-lg-12 col-md-12 col-xs-12 col-sm-12 sponsor-logo"
          >
            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <a href="#" class="sponsors-logo">
                  <img class="img-fluid" src={sponsor1} alt="" />
                </a>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
                <a href="#" class="sponsors-logo">
                  <img class="img-fluid" src={sponsor2} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-xs-6 col-sm-6 supported-by-mob">
            <h4 style={{ color: "white" }}>Supported By</h4>
          </div>
          <div
            class="col-lg-7 col-md-7 col-xs-6 col-sm-6 sponsor-logo-mob"
          >
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6">
                <a href="#" class="sponsors-logo">
                  <img class="img-fluid" src={sponsor1} alt="" />
                </a>
              </div>
              <div class="col-lg-3 col-md-3 col-xs-6 col-sm-6">
                <a href="#" class="sponsors-logo">
                  <img class="img-fluid" src={sponsor2} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SponsorClientLogo;

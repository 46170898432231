import React, { useState, useEffect } from "react";
import logo from "../assets/images/logos/logo.png";
import { Link } from "react-router-dom";

function Header() {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [showAwardsDropdown, setShowAwardsDropdown] = useState(false);
  const [showGleamsDropdown, setShowGleamsDropdown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const toggleAwardsDropdown = () => {
    setShowAwardsDropdown(!showAwardsDropdown);
    setShowGleamsDropdown(false);
  };

  const toggleGleamsDropdown = () => {
    setShowGleamsDropdown(!showGleamsDropdown);
    setShowAwardsDropdown(false);
  };

  const closeNavbar = () => {
    setIsNavbarCollapsed(true);
  };

  function calculateTimeLeft() {
    const eventDate = new Date("2024-06-25T23:59:59");
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <header id="header" className="header header-transparent">
      <div className="container">
        {/* <div className="countdown-timer" style={{ textAlign: "center", padding: "10px 0", color: "#fff", backgroundColor: "#000" }}>
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div> */}
        <nav id="navbarnav" className="navbar navbar-expand-lg navbar-light">
          {/* <!-- logo--> */}
          <a className="navbar-brand" href="https://gleamsawards.com">
            <img src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNavDropdown"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="icon icon-menu"></i>
            </span>
          </button>
          <div className={`collapse navbar-collapse ${!isNavbarCollapsed ? "show" : ""}`} id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item nav-link active">
                <Link to="/" className="ts-scroll" onClick={closeNavbar}>
                  Home
                </Link>
              </li>
              <li
                className={`nav-item dropdown ${showAwardsDropdown ? "show" : ""}`}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <a
                  className="nav-link ts-scroll"
                  href="#header"
                  onClick={toggleAwardsDropdown}
                >
                  Awards
                </a>
                <div
                  className={`dropdown-menu ${showAwardsDropdown ? "show" : ""}`}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "8px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <a
                    className="dropdown-item ts-scroll"
                    href="#categories"
                    onClick={closeNavbar}
                  >
                    Nominate
                  </a>
                  <a
                    className="dropdown-item ts-scroll"
                    href="#gleams-winners"
                    onClick={closeNavbar}
                  >
                    Gleams Winners
                  </a>
                  <a
                    className="dropdown-item ts-scroll"
                    href="#award-categories"
                    onClick={closeNavbar}
                  >
                    Award Categories
                  </a>
                  <a
                    className="dropdown-item ts-scroll"
                    href="#award-guidelines"
                    onClick={closeNavbar}
                  >
                    Award Guidelines
                  </a>
                </div>
              </li>
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#votes" onClick={closeNavbar}>
                  Vote
                </a>
              </li>
              <li className="nav-item nav-link">
                <Link to="/gallery" className="ts-scroll" onClick={closeNavbar}>
                  Gallery
                </Link>
              </li>
              <li
                className={`nav-item dropdown ${showGleamsDropdown ? "show" : ""}`}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <a
                  className="nav-link ts-scroll"
                  href="#header"
                  onClick={toggleGleamsDropdown}
                >
                  The Gleams
                </a>
                <div
                  className={`dropdown-menu ${showGleamsDropdown ? "show" : ""}`}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "8px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div className="dropdown-item ts-scroll">
                    <Link to="/about" onClick={closeNavbar}>
                      About
                    </Link>
                  </div>
                  <a
                    className="dropdown-item ts-scroll"
                    href="#sponsors"
                    onClick={closeNavbar}
                  >
                    Partners
                  </a>
                  <div className="dropdown-item ts-scroll">
                    <Link to="/contact" onClick={closeNavbar}>
                      Contact
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#blog" onClick={closeNavbar}>
                  Blog
                </a>
              </li>
              <li className="nav-item nav-link">
                <a className="ts-scroll" href="#magazine" onClick={closeNavbar}>
                  Magazine
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;

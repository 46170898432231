import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import bcas1 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas1.jpg';
import bcas2 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas2.jpg';
import bcas3 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas3.jpg';
import bcas4 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas4.jpg';
import bcas5 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas5.jpg';
import bcas6 from '../../assets/images/nominees/best_cable_station_of_the_year/bcas6.jpg';

function CableStation() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={bcas1}
            alt="bcas1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('MTV BASE TV', 'Best Cable Station of The Year')}
          />
          <img
            src={bcas2}
            alt="bcas2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Nigezie TV', 'Best Cable Station of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bcas3}
            alt="bcas3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Wap TV', 'Best Cable Station of The Year')}
          />
          <img
            src={bcas4}
            alt="bcas4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Hip TV', 'Best Cable Station of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bcas5}
            alt="bcas5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Sound City Africa', 'Best Cable Station of The Year')}
          />
          <img
            src={bcas6}
            alt="bcas6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Trace Naija', 'Best Cable Station of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default CableStation;

import React from 'react'

function Footer() {
  return (
    <div>  <div class="footer-area">
    <footer class="ts-footer ts-footer-item">
       <div class="container">
          <div class="row footer-border">
             <div class="col-lg-6">

                <div class="footer-menu mb-30">
                   <ul>
                      <li><a href="https://www.instagram.com/thegleamsawards?igsh=MzRlODBiNWFlZA==">Instagram</a></li>
                      <li><a href="https://x.com/thegleamsawards?t=XYzXfBulfWi7zfdNSQM8Xw&s=09">X</a></li>
                      <li><a href="https://www.facebook.com/profile.php?id=61558708183598&mibextid=ZbWKwL">Facebook</a></li>
                      <li><a href="#">Linkedin</a></li>
                      <li><a href="https://www.tiktok.com/@thegleamsawards?_t=8lxVKpoY7Rm&_r=1">Tiktok</a></li>
                   </ul>
                </div>

                <div class="ts-footer-social">
                   <ul>
                      <li>
                         <a href="https://www.facebook.com/profile.php?id=61558708183598&mibextid=ZbWKwL"><i class="fa fa-facebook"></i></a>
                      </li>
                      <li>
                         <a href="https://x.com/thegleamsawards?t=XYzXfBulfWi7zfdNSQM8Xw&s=09"><i class="fa fa-twitter"></i></a>
                      </li>
                      {/* <li>
                         <a href="#"><i className="fab fa-tiktok fa-2x"></i></a>
                      </li> */}
                      <li>
                         <a href="#"><i class="fa fa-linkedin"></i></a>
                      </li>
                      <li>
                         <a href="https://www.instagram.com/thegleamsawards?igsh=MzRlODBiNWFlZA=="><i class="fa fa-instagram"></i></a>
                      </li>
                   </ul>
                </div>

             </div>
             <div class="col-lg-6">
                <div class="newsletter-form">
                   <form action="#" method="post" class="media align-items-end">
                      <div class="email-form-group media-body">
                         <input type="email" name="email" id="newsletter-form-email" class="form-control" placeholder="Your Email" autocomplete="off" required="" />
                      </div>
                      <div class="d-flex ts-submit-btn">
                         <button class="btn">Subscribe</button>
                      </div>
                   </form>
                </div>
                <div class="copyright-text text-right">
                   <p>  Copyright © {new Date().getFullYear()} GleamsAward .
              All rights reserved.</p>
                </div>
             </div>
          </div>
       </div>
    </footer>
    <div class="BackTo">
       <a href="#" class="fa fa-angle-up" aria-hidden="true"></a>
    </div>

 </div></div>
  )
}

export default Footer
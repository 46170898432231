import React, { useState } from "react";
import { Container, TextField, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Simulate form submission
    try {
      const response = await axios.post("contact.php", formData);
      if (response.status === 200) {
        swal({
          title: "Success!",
          text: "Your message has been sent successfully.",
          icon: "success",
          button: "OK",
        });
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: "Failed to send your message. Please try again.",
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: "#fff",
        padding: "2rem",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            required
          />
          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#000", color: "#fff", width: "100%" }}
              type="submit"
            >
              Send
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default ContactForm;

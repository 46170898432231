import * as React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import mtvbase from "../assets/images/media/mtvbase.png";
import rhythm from "../assets/images/media/rhythm.png";
import stv from "../assets/images/media/STV.png";
import pulseng from "../assets/images/media/pulseng.png";
import osg from "../assets/images/media/osg.png";
import bm from "../assets/images/media/bm.png";
import thebeat from "../assets/images/media/thebeat.png";
import "../assets/css/grid.css";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 80,
  height: 80,
  //   padding: theme.spacing(2),
  //   textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

export default function SquareCorners() {
  return (
    <div style={{marginTop: "30px"}}>
    <h3 style={{color: '#ffffff', marginBottom: '20px'}}>Media</h3>
      <div
        class="row media-mob"
        style={{
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: 'yellow'
        }}
      >
        <div
          class="col-lg-12 col-md-12 col-xs-12 col-sm-12"
          style={{ marginBottom: "20px" }}
        >
          <Stack
            direction="row"
            spacing={2}
            style={{
              // backgroundColor: "yellow",
              //   width: "800px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={rhythm} alt="rhythm" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={mtvbase} alt="rhythm" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={stv} alt="stv" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={osg} alt="osg" />
              </a>
            </DemoPaper>
          </Stack>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
          <Stack
            direction="row"
            spacing={2}
            style={{
              // backgroundColor: "yellow",
              // width: "800px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={pulseng} alt="pulseng" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={bm} alt="bm" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={thebeat} alt="thebeat" />
              </a>
            </DemoPaper>
          </Stack>
        </div>
      </div>
      <div class=" media-des">
        <div
          class="row"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            style={{
              // backgroundColor: "yellow",
              width: "800px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={rhythm} alt="rhythm" />
              </a>
            </DemoPaper>
            {/* <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={mtvbase} alt="rhythm" />
              </a>
            </DemoPaper> */}
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={stv} alt="stv" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={osg} alt="osg" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={pulseng} alt="pulseng" />
              </a>
            </DemoPaper>
            <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={bm} alt="bm" />
              </a>
            </DemoPaper>
            {/* <DemoPaper square={false}>
              <a href="#">
                <img className="img-fluid" src={thebeat} alt="thebeat" />
              </a>
            </DemoPaper> */}
          </Stack>
        </div>
      </div>
    </div>
  );
}

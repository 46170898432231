import React from 'react'
import Header from '../components/Header'
import IntroStart from '../components/IntroStart'
import Footer from '../components/Footer'

function About() {
  return (
    <div>
      <Header />
      <IntroStart />
      <Footer />
    </div>
  )
}

export default About
import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import btp1 from '../../assets/images/nominees/best-tv_personality/btp1.jpg';
import btp2 from '../../assets/images/nominees/best-tv_personality/btp2.jpg';
import btp3 from '../../assets/images/nominees/best-tv_personality/btp3.jpg';
import btp4 from '../../assets/images/nominees/best-tv_personality/btp4.jpg';
// import btp5 from '../../assets/images/nominees/best-tv_personality/btp5.jpg';
import btp6 from '../../assets/images/nominees/best-tv_personality/btp6.jpg';
import btp7 from '../../assets/images/nominees/best-tv_personality/btp7.jpg';

function TvPersonality() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={btp1}
            alt="btp1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Maupe Ogun', 'Best Tv Personality')}
          />
          <img
            src={btp2}
            alt="btp2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Kayode Bakerr', 'Best Tv Personality')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={btp3}
            alt="btp3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Rufai Oseni', 'Best Tv Personality')}
          />
          <img
            src={btp4}
            alt="btp4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Morayo Brown', 'Best Tv Personality')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={btp6}
            alt="btp6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Seun Okinbaloye', 'Best Tv Personality')}
          />
          <img
            src={btp7}
            alt="btp7"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Seun Okinbaloye', 'Best Tv Personality')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default TvPersonality;

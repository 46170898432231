import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import eac1 from '../../assets/images/nominees/emerging_actor/eac1.jpg';
import eac2 from '../../assets/images/nominees/emerging_actor/eac2.jpg';
// import eac3 from '../../assets/images/nominees/emerging_actor/eac3.jpg';
import eac4 from '../../assets/images/nominees/emerging_actor/eac4.jpg';
// import eac5 from '../../assets/images/nominees/emerging_actor/eac5.jpg';
import eac6 from '../../assets/images/nominees/emerging_actor/eac6.jpg';
import eac7 from '../../assets/images/nominees/emerging_actor/eac7.jpg';
import eac8 from '../../assets/images/nominees/emerging_actor/eac8.jpg';

function CableStation() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={eac1}
            alt="eac1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Chuks Joseph', 'Emerging Actor')}
          />
          <img
            src={eac2}
            alt="eac2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Dike Miracle Chidi', 'Emerging Actor')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={eac7}
            alt="eac7"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Eronini Osinachim', 'Emerging Actor')}
          />
           <img
            src={eac6}
            alt="eac6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('David Ezekiel', 'Emerging Actor')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={eac8}
            alt="eac8"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Chidera David', 'Emerging Actor')}
          />
        
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default CableStation;

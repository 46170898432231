import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import woy1 from '../../assets/images/nominees/woman_of_the_year/woy1.jpg';
import woy2 from '../../assets/images/nominees/woman_of_the_year/woy2.jpg';
import woy3 from '../../assets/images/nominees/woman_of_the_year/woy3.jpg';
import woy4 from '../../assets/images/nominees/woman_of_the_year/woy4.jpg';
import woy5 from '../../assets/images/nominees/woman_of_the_year/woy5.jpg';
import woy6 from '../../assets/images/nominees/woman_of_the_year/woy6.jpg';

function WomanOfTheYear() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={woy1}
            alt="woy1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Deola Art Alade', 'Woman Of The Year')}
          />
          <img
            src={woy2}
            alt="woy2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Tobi Amusan', 'Woman Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={woy3}
            alt="woy3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Mariam Mohammed Maktoub', 'Woman Of The Year')}
          />
          <img
            src={woy4}
            alt="woy4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('MO Abudu', 'Woman Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={woy5}
            alt="woy5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Funke Akindele', 'Woman Of The Year')}
          />
          <img
            src={woy6}
            alt="woy6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Sen. Ireti Kinigbe', 'Woman Of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default WomanOfTheYear;

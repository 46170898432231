import React, { useState, useEffect } from 'react';
// import './Banner.css';  // Assuming you have a CSS file for the styles

function Banner() {
  const backgroundImageUrl = require('../assets/images/hero_area/banner6.jpg'); // Ensure correct path

  const calculateTimeLeft = () => {
    const eventDate = new Date("2024-06-25T23:59:59");
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <section className="hero-area content-left" id="home">
      <div 
        className="banner-item" 
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content-wrap">
                <div 
                  className="countdown wow fadeInUp" 
                  data-wow-duration="1.5s" 
                  data-wow-delay="800ms"
                >
                  <div className="counter-item">
                    <i className="icon icon-ring-1Asset-1"></i>
                    <span className="days">{String(timeLeft.days).padStart(2, '0')}</span>
                    <div className="smalltext">Days</div>
                  </div>
                  <div className="counter-item">
                    <i className="icon icon-ring-4Asset-3"></i>
                    <span className="hours">{String(timeLeft.hours).padStart(2, '0')}</span>
                    <div className="smalltext">Hours</div>
                  </div>
                  <div className="counter-item">
                    <i className="icon icon-ring-3Asset-2"></i>
                    <span className="minutes">{String(timeLeft.minutes).padStart(2, '0')}</span>
                    <div className="smalltext">Minutes</div>
                  </div>
                  <div className="counter-item">
                    <i className="icon icon-ring-4Asset-3"></i>
                    <span className="seconds">{String(timeLeft.seconds).padStart(2, '0')}</span>
                    <div className="smalltext">Seconds</div>
                  </div>
                </div>

                <h1 
                  className="banner-title wow fadeInUp banner-gleams" 
                  data-wow-duration="1.5s" 
                  data-wow-delay="700ms"
                >
                  Gleams 
                </h1> <p />
                <h1 
                  className="banner-title wow fadeInUp banner-awards" 
                  data-wow-duration="1.5s" 
                  data-wow-delay="700ms"
                >
                  Awards
                </h1>
                <div 
                  className="banner-btn wow fadeInUp" 
                  data-wow-duration="1.5s" 
                  data-wow-delay="800ms"
                >
                  <a href="#votes" className="btn">Vote</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import VoteForm from "./VoteForm";
import voteIcon from "../assets/images/VoteIcon.jpg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

function Vote() {
  const [open, setOpen] = useState(false);
  const [selectedVote, setSelectedVote] = useState("");

  const votes = [
    { title: "BEST MUSIC VIDEO FEMALE DIRECTOR OF THE YEAR", url: "/vote/music-video-female-director" },
    { title: "BEST MUSIC VIDEO MALE DIRECTOR OF THE YEAR", url: "/vote/music-video-male-director" },
    { title: "BEST RADIO STATION OF THE YEAR", url: "/vote/radio-station" },
    { title: "BEST TV STATION OF THE YEAR", url: "/vote/tv-station" },
    { title: "BEST CABLE STATION OF THE YEAR", url: "/vote/cable-station" },
    { title: "BEST BLOG OF THE YEAR", url: "/vote/blog" },
    { title: "BEST ONLINE MUSIC PLATFORM OF THE YEAR", url: "/vote/online-music-platform" },
    { title: "BEST ACTOR OF THE YEAR", url: "/vote/actor" },
    { title: "BEST ACTRESS OF THE YEAR", url: "/vote/actress" },
    { title: "EMERGING ACTOR OF THE YEAR", url: "/vote/emerging-actor" },
    { title: "EMERGING ACTRESS OF THE YEAR", url: "/vote/emerging-actress" },
    { title: "BEST TV PERSONALITY OF THE YEAR", url: "/vote/tv-personality" },
    { title: "BEST SUPPORTIVE BRAND OF THE YEAR", url: "/vote/supportive-brand" },
    { title: "BEST CONTENT CREATOR OF THE YEAR", url: "/vote/content-creator" },
    { title: "BIGGEST SONG OF THE YEAR", url: "/vote/biggest-song" },
    { title: "BEST MALE ARTIST OF THE YEAR", url: "/vote/male-artist" },
    { title: "BEST FEMALE ARTIST OF THE YEAR", url: "/vote/female-artist" },
    { title: "BIGGEST ARTIST OF THE YEAR (AFRICA)", url: "/vote/biggest-artist-africa" },
    { title: "EMERGING ARTIST OF THE YEAR", url: "/vote/emerging-artist" },
    { title: "MUSIC PRODUCER OF THE YEAR", url: "/vote/music-producer" },
    { title: "BEST STREET SONG OF THE YEAR", url: "/vote/street-song" },
    { title: "BEST MOVIE OF THE YEAR", url: "/vote/movie" },
    { title: "BEST MOVIE DIRECTOR OF THE YEAR", url: "/vote/movie-director" },
    { title: "MAN OF THE YEAR", url: "/vote/man-of-the-year" },
    { title: "HUMANITARIAN OF THE YEAR", url: "/vote/humanitarian" },
    { title: "YOUNG ACHIEVER OF THE YEAR (MALE)", url: "/vote/young-achiever-male" },
    { title: "YOUNG ACHIEVER OF THE YEAR (FEMALE)", url: "/vote/young-achiever-female" },
    { title: "BEST FASHION DESIGNER OF THE YEAR", url: "/vote/fashion-designer" },
    { title: "WOMAN OF THE YEAR", url: "/vote/woman-of-the-year" },
    { title: "ENTREPRENEUR OF THE YEAR", url: "/vote/entrepreneur" },
    { title: "BEST PODCAST OF THE YEAR", url: "/vote/podcast" },
    { title: "LIFETIME ACHIEVEMENT AWARD", url: "/vote/lifetime-achievement" },
    { title: "SPECIAL RECOGNITION AWARD", url: "/vote/special-recognition" },
  ];

  const handleButtonClick = (title) => {
    setSelectedVote(title);
    setOpen(true);
  };

  return (
    <div
      className="row"
      id="votes"
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h4 style={{ color: "#ffffff", marginBottom: "20px" }}>VOTE</h4>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            marginBottom: "30px",
          }}
        >
          {votes.map((vote, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Link to={vote.url} style={{ textDecoration: 'none' }}>
                <Button
                  component={Item}
                  fullWidth
                  onClick={() => handleButtonClick(vote.title)}
                  sx={{
                    borderRadius: "4px",
                    textTransform: "none", // Prevent automatic capitalization
                    padding: "10px 5px", // Add padding to the button
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={voteIcon}
                    alt="Vote Icon"
                    style={{
                      width: "100%",
                      maxWidth: "100px", // Increased max width for the icon
                      height: "auto",
                      marginBottom: "10px", // Space between icon and text
                    }}
                  />
                  {capitalizeEachWord(vote.title)}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
      <VoteForm open={open} setOpen={setOpen} vote={selectedVote} />
    </div>
  );
}

export default Vote;

import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import image1 from "../assets/images/awards/1.png";
import image2 from "../assets/images/awards/2.png";
import image3 from "../assets/images/awards/3.png";
import image4 from "../assets/images/awards/4.png";
import image5 from "../assets/images/awards/5.png";
import image6 from "../assets/images/awards/6.png";
// import image7 from "../assets/images/awards/7.png";
// import image8 from "../assets/images/awards/8.png";
// import image9 from "../assets/images/awards/9.png";
import image10 from "../assets/images/awards/10.jpg";
import image11 from "../assets/images/awards/11.jpg";
// import image12 from "../assets/images/awards/12.jpg";
import image13 from "../assets/images/awards/13.jpg";
import image14 from "../assets/images/awards/14.jpg";
import image15 from "../assets/images/awards/15.jpg";
import image16 from "../assets/images/awards/16.jpg";
import image17 from "../assets/images/awards/17.jpg";
// import image18 from "../assets/images/awards/18.jpg";
// import image19 from "../assets/images/awards/19.jpg";
// import image20 from "../assets/images/awards/20.jpg";

// Example image URLs (replace these with your actual image URLs)
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  // image7,
  // image8,
  // image9,
  image10,
  image11,
  // image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  // image18,
  // image19,
  // image20
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: 0, // Remove padding to ensure the image covers the entire item
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%", // Ensure the item takes the full height of the grid
  overflow: "hidden", // Ensure the image doesn't overflow the item
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.3s, box-shadow 0.3s", // Add transition for smooth hover effect
  "&:hover": {
    transform: "scale(1.05)", // Scale the image on hover
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Add shadow on hover
  },
});

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover", // Make the image cover the entire grid item
});

function Gallery() {
  return (
    <div className="row">
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          spacing={0.5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          {images.map((image, index) => (
            <Grid item xs={6} sm={4} md={4} lg={1.5} key={index} style={{ height: "150px" }}>
              <Item>
                <ImageContainer>
                  <Image src={image} alt={`Image ${index + 1}`} />
                </ImageContainer>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default Gallery;

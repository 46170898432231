import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import bac1 from '../../assets/images/nominees/best_actor_of_the_year/bac1.jpg';
import bac2 from '../../assets/images/nominees/best_actor_of_the_year/bac2.jpg';
import bac3 from '../../assets/images/nominees/best_actor_of_the_year/bac3.jpg';
import bac4 from '../../assets/images/nominees/best_actor_of_the_year/bac4.jpg';
import bac5 from '../../assets/images/nominees/best_actor_of_the_year/bac5.jpg';
import bac6 from '../../assets/images/nominees/best_actor_of_the_year/bac6.jpg';


function Actor() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={bac1}
            alt="bac1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Stan Nze', 'Best Actor Of The Year')}
          />
          <img
            src={bac2}
            alt="bac2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Itele D Icon', 'Best Actor Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bac3}
            alt="bac3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Chimezie Imo', 'Best Actor Of The Year')}
          />
          <img
            src={bac4}
            alt="bac4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Kunle Remi', 'Best Actor Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bac5}
            alt="bac5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Uzor Arukwe', 'Best Actor Of The Year')}
          />
          <img
            src={bac6}
            alt="bac6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Uzee Usman', 'Best Actor Of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default Actor;

import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function LoaderModal({ open }) {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <CircularProgress />
      </Box>
    </Modal>
  );
}

export default LoaderModal;

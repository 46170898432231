import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import enty1 from '../../assets/images/nominees/enterpreneur/enty1.jpg';
import enty2 from '../../assets/images/nominees/enterpreneur/enty2.jpg';
import enty3 from '../../assets/images/nominees/enterpreneur/enty3.jpg';
import enty4 from '../../assets/images/nominees/enterpreneur/enty4.jpg';
import enty5 from '../../assets/images/nominees/enterpreneur/enty5.jpg';
import enty6 from '../../assets/images/nominees/enterpreneur/enty6.jpg';

function Entrepreneur() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={enty1}
            alt="enty1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Chinedu Azodoh', 'Enterpreneur Of The Year')}
          />
          <img
            src={enty2}
            alt="enty2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Abegunde Abiodun', 'Enterpreneur Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={enty3}
            alt="enty3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Oluwatobi Ajayi', 'Enterpreneur Of The Year')}
          />
          <img
            src={enty4}
            alt="enty4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Odunayo Eweniyi', 'Enterpreneur Of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={enty5}
            alt="enty5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Uche Pedro', 'Enterpreneur Of The Year')}
          />
          <img
            src={enty6}
            alt="enty6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Linus Ifejika', 'Enterpreneur Of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default Entrepreneur;

import React, { useState } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import backgroundImage from '../assets/images/banner/play3.jpg'; // Replace with the correct path to your background image

const styles = {
  container: {
    width: '100%',
    height: '550px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  playButton: {
    color: '#fff',
    fontSize: '200px',
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalContent: {
    position: 'relative',
    padding: 0,
    border: 'none',
    background: 'none',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: '#fff',
    zIndex: 9999, // Ensure the close button is above other content
  },
};

const videoOpts = {
  width: '100%',
  height: '100%',
  playerVars: {
    autoplay: 1,
  },
};

Modal.setAppElement('#root'); // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function VideoBackground() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div>
      <div style={styles.container} onClick={openModal}>
        <PlayCircleOutlineIcon style={styles.playButton} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: styles.modalContent,
          overlay: styles.overlay,
        }}
        contentLabel="YouTube Video Modal"
      >
        <button style={styles.closeButton} onClick={closeModal}>
          <CloseIcon />
        </button>
        <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
          <YouTube videoId="dQw4w9WgXcQ" opts={videoOpts} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
        </div>
      </Modal>
    </div>
  );
}

export default VideoBackground;

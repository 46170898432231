import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VoteForm from '../../components/VoteForm';
import bas1 from '../../assets/images/nominees/best_actress_of_the_year/bas1.jpg';
import bas2 from '../../assets/images/nominees/best_actress_of_the_year/bas2.jpg';
import bas3 from '../../assets/images/nominees/best_actress_of_the_year/bas3.jpg';
import bas4 from '../../assets/images/nominees/best_actress_of_the_year/bas4.jpg';
import bas5 from '../../assets/images/nominees/best_actress_of_the_year/bas5.jpg';
import bas6 from '../../assets/images/nominees/best_actress_of_the_year/bas6.jpg';

function Actress() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [nomineeName, setNomineeName] = useState(''); // State to store nominee name
  const [nomineeTitle, setNomineeTitle] = useState(''); // State to store nominee title

  const openModal = (name, title) => {
    setNomineeName(name);
    setNomineeTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '120px',
          padding: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {/* Clickable images */}
          <img
            src={bas1}
            alt="bas1"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Uche Montana', 'Best Actress of The Year')}
          />
          <img
            src={bas2}
            alt="bas2"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Kehinde Bankole', 'Best Actress of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bas3}
            alt="bas3"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Sophie Alakija', 'Best Actress of The Year')}
          />
          <img
            src={bas4}
            alt="bas4"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Chinenye Nneme', 'Best Actress of The Year')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* Clickable images */}
          <img
            src={bas5}
            alt="bas5"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Sunshine Rosman', 'Best Actress of The Year')}
          />
          <img
            src={bas6}
            alt="bas6"
            style={{ width: '300px', height: 'auto', margin: '10px', cursor: 'pointer' }}
            onClick={() => openModal('Bimbo Ademoye', 'Best Actress of The Year')}
          />
        </div>
      </div>
      {/* Modal */}
      <VoteForm open={isModalOpen} setOpen={setIsModalOpen} nomineeName={nomineeName} nomineeTitle={nomineeTitle} />
      <Footer />
    </div>
  );
}

export default Actress;
